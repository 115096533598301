import request from '@/utils/request'

// 修改绑定手机号
export function alterBindPhoNum(data) {
  return request({
    url: '/api/sale/customer/phone',
    method: 'post',
    data
  })
}




